import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Overhead Squat 8-8-8@ 70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`50 Double Unders`}</p>
    <p>{`25 OHS (95/65)`}</p>
    <p>{`40 Double Unders`}</p>
    <p>{`20 OHS`}</p>
    <p>{`30 Double Unders`}</p>
    <p>{`15 OHS`}</p>
    <p>{`20 Double Unders`}</p>
    <p>{`10 OHS`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      